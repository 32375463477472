import React from "react"
import {Link} from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

const policyHtml = ({data,location}) => (
	<Layout>
	  <Seo
		pagetitle="POLICY"
		pagedesc="MAZINのポリシーページ"
		pagepath={location.pathname}
	  />
      <div className="container-fluid policy">
        <div className="row">
          <ul className="breadcrumb">
            <li><a href="<?php echo home_url(); ?>">TOP</a></li>
            <li>POLICY</li>
          </ul>{/*.breadcrumb*/}
          <h1>POLICY</h1>
          <div className="contents-box">
            <h3 className="underline">個人情報の取り扱いについて</h3><div />
            <div className="contents-comment">
              株式会社MAZIN（以下、「当社」といいます。）は、「個人情報の保護に関する法律」（以下、「個人情報保護法」といいます。）に基き、当社が新たに取得する又はすでにお預かりしているお客様を識別することができるお客様の氏名、会社名、住所、電話番号、メールアドレスなどの情報（以下、「個人情報」といいます。）を以下の方針に沿って取り扱うことを公表いたします。
            </div>
            <div className="privacy">
              <div className="policy-content">
                <h2>一：個人情報の利用目的</h2>
                <div>
                  <div className="comment-br">
                    当社は個人情報を下記の目的に限って利用させていただきます。 なお、下記の目的以外に利用する場合はその都度ご同意をいただくものとします。
                  </div>
                  <div className="comment-br">
                    1.当社が取り扱っている商品、当社が有益と考える諸情報の提供またはサービス（新商品もしくは新サービスを含む）に関する営業活動上のご案内。
                  </div>
                  <div className="comment-br">
                    2.新商品、新サービスの企画・開発またはお客様に対するサービス向上に関する施策検討のためのアンケート調査の実施。
                  </div>
                  <div className="comment-br">
                    3.製品の発送、アフターサービス、お客様からのお問合せへのご回答などの業務の遂行。
                  </div>
                </div>
              </div>{/*.policy-content*/}
              <div className="policy-content">
                <h2>二：個人情報の取り扱い方針</h2>
                <div>
                  <div className="comment-br">
                    1.当社は個人情報を適切に管理し、以下の場合を除いて第三者へ提供することはございません。但し、個人情報保護法に規定する例外事項に該当する場合を除きます。
                  </div>
                  <div className="comment-br">
                    (1)事前にお客様の同意をいただいた場合。
                  </div>
                  <div className="comment-br">
                    (2)上記「1.個人情報の利用目的」で公表している利用目的の範囲内で業務を第三者に委託する場合(但し、この場合、当社は業務委託先に対して個人情報の厳格な管理を要求し適切に指揮監督いたします。)。
                  </div>
                  <div className="comment-br">
                    2.上記「1.個人情報の利用目的」で公表している利用目的の範囲内で個人情報を当社の子会社または関連会社と次の範囲内において共同利用させていただくことがあります(但し、この場合、当社は共同利用先に対して個人情報の厳格な管理を要求し適切に指揮監督いたします。)。
                  </div>
                  <div className="comment-br">
                    (1)個人情報を共同して利用する者の範囲<br />
                    当社の子会社または関連会社
                  </div>
                  <div className="comment-br">
                    (2)利用する者の利用目的<br />
                    当社の利用目的に準じるものとします
                  </div>
                  <div className="comment-br">
                    (3)当該個人情報の管理について責任を有する者の氏名又は名称<br />
                    株式会社MAZIN　代表取締役
                  </div>
                </div>
              </div>{/*.policy-content*/}
              <div className="policy-content">
                <h2>三：個人情報取扱事業者の名称</h2>
                <div>
                  株式会社MAZIN
                </div>
              </div>{/*.policy-content*/}
              <div className="policy-content">
                <h2>四：個人情報の安全・適正管理</h2>
                <div>
                  当社は、個人情報を保管並びに利用するに際し、個人情報の漏洩、紛失、滅失又は改ざんなどや不正アクセスを防止のために適切なセキュリティ対策を実施します。また、上記「1.個人情報の利用目的」の達成に必要な範囲内において、個人情報を正確かつ最新の内容に保つように努めます。
                </div>
              </div>{/*.policy-content*/}
              <div className="policy-content">
                <h2>五：当社が保有する個人情報についてのお問い合わせ</h2>
                <div>
                  当社が保有するお客様の個人情報について開示・訂正・利用停止・削除・利用目的の通知をご希望される場合は<a href="<?php echo home_url('/contact'); ?>">こちらのフォーム</a>（※）で各請求の方法をお問い合わせください。当社規定に基き適切に対応させていただきます。
                </div>
              </div>{/*.policy-content*/}
              <div className="policy-content">
                <h2>六：その他</h2>
                <div>
                  当社は、当社が保有するお客様の個人情報を保護するため個人情報保護法を含め関係する法令・ガイドラインなどを遵守します。 また、当社は当該法令・ガイドラインなどの改正に対応して、この「個人情報の取扱いについて」を適宜変更することがあります。 お客様との電話対応時の録音は、ご注文・お問い合わせの内容などを正しく把握し、今後のサービス向上に役立てるため利用させていただいております。あらかじめご了承ください。※お問い合わせ内容はSSLにより暗号化され保護されますが、SSL未対応のブラウザをご利用の場合や、 設定などによっては当該ページにアクセスできない場合がございます。
                </div>
              </div>{/*.policy-content*/}
              <div className="policy-content">
                <h2>クッキー（Cookie）について</h2>
                <div>
                  当ウェブサイトでは、お客様の利便性向上(ログイン状態の保持など)、掲載内容の最適化、第三者配信事業者からの広告配信などにクッキーを使用しています
                </div>
              </div>{/*.policy-content*/}
              <div className="policy-content">
                <h2>一：クッキーとは</h2>
                <div>
                  ウェブサイトから送信されるお客様のブラウザを識別するための符号であり、これによりお客様のコンピューターからのウェブサイト閲覧情報等のデータをクッキー発行者のサーバーに記録することが可能となるものです。クッキーにはお客様を特定する個人情報は含まれません。お客様ご自身でブラウザ設定を操作することで、クッキーの受け取りを拒否することや、受け取られたクッキーを削除することも可能です。お客様がクッキーを無効とした場合、サービスの一部が適切に機能しないことがあります。
                </div>
              </div>{/*.policy-content*/}
              <div className="policy-content">
                <h2>二：クッキーを使用した第三者配信事業者からの広告配信について</h2>
                <div>
                  Google、Yahooを含む第三者配信事業者によりインターネット上のさまざまなWebサイトに当社の広告が掲載されています。 各事業者はクッキーを使用して、当サイトへの過去のアクセス情報に基づいて広告を配信します。 取得したクッキーは、各事業者のプライバシーポリシーに従って取り扱われます。お客様は各事業者が運営するオプトアウトページにアクセスして、当該事業者によるクッキーの使用を無効にできます。
                </div>
              </div>{/*.policy-content*/}
            </div>{/*.privacy*/}
            <Link to={`/`}><div className="link-top">TOP</div></Link>
          </div>{/*.contents-box*/}
        </div>{/*.row*/}
      </div>{/*.container-fluid*/}



	
	 </Layout>

)

export default policyHtml